import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';

const StatCards = ({ offlinecount, onlinecount, planexpiry }) => {
  return (
    <React.Fragment>
      <Grid container spacing={3} marginBottom="20px">
        <Grid item xs={6} md={6}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Offline
              </Typography>
              <Typography variant="h4" color="primary">
                {!offlinecount ? "0" : offlinecount}
              </Typography>
            </Box>
            <WifiTetheringOffIcon sx={{ fontSize: {xs:44,sm:64}, opacity: 0.6,color:"red" }} />
          </Card>
        </Grid>

        <Grid item xs={6} md={6}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Online
              </Typography>
              <Typography variant="h4" color="primary">
              {!onlinecount ? "0" : onlinecount}
              </Typography>
            </Box>
            <OnlinePredictionIcon sx={{ fontSize: {xs:44,sm:64}, opacity: 0.6,color:"green" }} />
          </Card>
        </Grid>

        {/* <Grid item xs={12} md={4}>
          <Card
            sx={{
              display: "flex",
              padding: 2,
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle2" sx={{ color: "#8c8c8c" }}>
                Plan Expiry
              </Typography>
              <Typography variant="h4" color="primary">
                {!planexpiry ? "NA" : planexpiry}
              </Typography>
            </Box>
            <AssignmentTurnedIn
              sx={{ fontSize: 64, color: "#90aec6", opacity: 0.6 }}
            />
          </Card>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default StatCards;
